
import {
  reactive,
  toRefs,
  computed,
  watch,
  inject,
  onMounted,
} from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"

import { getAddressApi } from "@/api/address"
import { 
  defaultError,
  formatPhone,
} from '@/utils/common.js'
import { SET_ORDER_ADDRESS } from '@/store/mutation-types.js'

export default {
  name: 'AddressList',
  components: {
    
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const toast = inject('toast')
    const loading = inject('loading')

    const state = reactive({
      list: [],
    })

    const isFullScreen = computed(() => store.state.isFullScreen)

    const orderAdress = computed(() => store.state.orderAdress)
    onMounted( async () => {
      loading.show()
      await getAddress()
      loading.hide()
    })
    async function getAddress() {
      const res = await getAddressApi()
    
      if(res.code === 0) {
        state.list = res.data.map( x => {
          x.formatPhone = formatPhone(x.telephone)
          if(x.id === orderAdress.value.id) {
            x.isSelect = true
          }
          else {
            x.isSelect = false
          }

          return x
        })

        const orderAdressIndex = state.list.findIndex( x => x.isSelect )
        if(orderAdressIndex > -1) {
          // 从编辑页面返回时，可能更新当前选中的地址
          store.commit(SET_ORDER_ADDRESS, state.list[orderAdressIndex])
        }
        else {
          // 在编辑页面，当前选中的地址被删除了，所以更新为默认的地址
          store.commit(SET_ORDER_ADDRESS, state.list.find( x => x.isDefault ))
        }
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }
    watch(
      () => orderAdress.value,
      (newAdress) => {
        state.list = state.list.map( x => {
          if(newAdress && x.id === newAdress.id) {
            x.isSelect = true
          }
          else {
            x.isSelect = false
          }

          return x
        })
      }
    )

    const selectAddress = (index) => {
      store.commit(SET_ORDER_ADDRESS, state.list[index])
      router.go(-1)
    }

    const toAddAddress = () => {
      router.push({name: 'FillAddress'})
    }

    const toEdit = (index) => {
      router.push({ name: 'EditAddress', params: { id: state.list[index].id } })
    }

    return {
      ...toRefs(state),
      isFullScreen,
      toAddAddress,
      selectAddress,
      toEdit,
    }
  },
} 