<template>
  <div class="addressList address-wrap" :class="{isFull: isFullScreen}">
    <div class="addressList-header">我的收货地址</div>

    <div class="address-content">
      <div
        v-for="(item, index) in list"
        :key="item.id"
        class="myAddress-item">
        <div @click="selectAddress(index)" class="myAddress-spotBox">
          <img v-if="item.isSelect" src="@/assets/images/icon/spot.png" class="myAddress-spot">
        </div>

        <div class="myAddress-content">
          <div class="myAddress-info">
            <div @click="selectAddress(index)" class="myAddress-name">{{item.consignee}}</div>
            <div @click="selectAddress(index)" class="myAddress-phone">{{item.formatPhone}}</div>
            <div @click="toEdit(index)" class="editBtn-box">
              <img src="@/assets/images/icon/edit.png" class="editBtn-img">
              <div class="editBtn-txt">编辑</div>
            </div>
          </div>

          <div @click="selectAddress(index)" class="myAddress-area">
            <span v-if="item.isDefault" class="myAddress-default">[默认]</span>
            {{item.province}} {{item.city}} {{item.district}} {{item.street}}
          </div>
        </div>
      </div>
    </div>

    <div class="addressList-footer">
      <div @click="toAddAddress" class="btn">+添加地址</div>
    </div>
  </div>
</template>

<script src='./AddressList.js'></script>

<style scoped lang="scss" src="./AddressList.scss"></style>